import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { constants } from '../../constants';

function ROICalculator()
{
  const [hourlyRate, setHourlyRate] = useState('50');
  const [timeSavedPerWeek, setTimeSavedPerWeek] = useState('1');
  const [annualEarnings, setAnnualEarnings] = useState('');

  const handleSubmit = (e) =>
  {
    e.preventDefault();
  }

  return (
    <div className="py-32">

    <div className="max-w-4xl m-auto text-center">
      <h1 className="mt-2 text-4xl text-slate-800 leading-8 font-extrabold tracking-tight sm:text-5xl">
            <Trans>Calculator</Trans>
          </h1>
          <p className="mt-4 max-w-2xl text-xl lg:mx-auto">
            <Trans>How much can you save by mastering ChatGPT?</Trans>
          </p>
    </div>

<div className="bg-white text-md grid grid-cols-1 md:grid-cols-2 p-6 max-w-6xl m-auto">

       <form className="max-w-xl" onSubmit={handleSubmit}>

       <label htmlFor="hourly-rate" className="block font-medium text-gray-700">
        Your hourly rate (<span className="font-bold text-lg">€{hourlyRate}</span>)
      </label>

      <input id="hourly-rate" type="range"
        min={10}
        max={500}
        step={10}
        value={hourlyRate}
        onChange={e => setHourlyRate(e.target.value)} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />


      <label htmlFor="time-saved" className="mt-5 block font-medium text-gray-700">
        Time saved per week (<span className="font-bold text-lg">{timeSavedPerWeek}h</span>)
      </label>
      <input id="time-saved" type="range"
        min={1}
        max={74}
        step={1}
        value={timeSavedPerWeek}
        onChange={e => setTimeSavedPerWeek(e.target.value)} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer" />

      <button
        className="mt-4 font-bold text-white py-2 px-4 rounded-lg bg-indigo-600 hover:bg-indigo-700"
        type="submit"
      >
        Calculate
      </button>

    </form>


    <div className="mt-4 max-w-4xl m-auto">
      <table className="w-full text-md text-left text-gray-700">
          <tbody>
              <tr className="bg-white border-b">
                  <th scope="row" className="px-6 py-4">
                    Number of hours earned per year
                  </th>
                  <td className="px-6 py-4 font-medium">
                      { parseInt(timeSavedPerWeek) * 52 }h
                  </td>
              </tr>
              <tr className="bg-white border-b">
                <th scope="row" className="px-6 py-4">
                  Amount earned if you work during this time
                  </th>
                  <td className="px-6 py-4 font-medium">
                    €{ parseInt(timeSavedPerWeek) * 52 * parseInt(hourlyRate) }
                  </td>
              </tr>
              <tr className="bg-white border-b">
                <th scope="row" className="px-6 py-4">
                      Price of our ChatGPT guide
                  </th>
                  <td className="px-6 py-4 font-medium text-indigo-600">
                      -€19.99
                  </td>
              </tr>
              <tr className="bg-white border-b ">
                <th scope="row" className="px-6 py-4">
                      Total saved
                  </th>
                  <td className="px-6 py-4 text-green-800 font-bold">
                    €{parseInt(timeSavedPerWeek) * 52 * parseInt(hourlyRate) - 19.99}
                  </td>
              </tr>
          </tbody>
      </table>
      <div className="text-center">
      <a
                href={constants.links.checkout}
                target="_blank"
                className="mt-8 inline-flex items-center rounded-md border border-transparent rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 px-5 py-3 font-bold shadow"
              >
                <Trans>➜ Learn ChatGPT now for €19.99</Trans>
              </a>
      </div>
    </div>
</div>


    </div>
  );
}

export default ROICalculator;
