/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { Component } from 'react';
import { CheckIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import { constants } from '../../constants';

const sections = [
  {
    title: '💼  For executives',
    content: 'Learn how to use ChatGPT to save time on tasks like composing emails, writing reports, generating new strategies and business ideas, and more...'
  },
  {
    title: '🦄  For entrepreneurs',
    content: 'Learn how to use ChatGPT to generate growth strategies, find investors, get help to recrute talents, save time on admin tasks, and more...'
  },
  {
    title: '👨🏼‍💼  For sales',
    content: 'Learn how to use ChatGPT to find new prospects, generate highly-converting cold emails, get help in the negotiation processes, and more...'
  },
  {
    title: '👨‍🎨  For marketers',
    content: 'Learn how to use ChatGPT to generate growth marketing plans, improve SEO, write social media posts, blog posts, and more...'
  },
  {
    title: '👨🏼‍💻  For developers',
    content: 'Learn how to use ChatGPT to write code faster, find and compare new technologies, perform code reviews, and more...'
  },
  {
    title: '👩🏼‍🎓  For students',
    content: 'Learn how to use AI-based technologies to prepare yourself for the professional world.'
  }
];

export default class Features extends Component
{

  public render(): React.ReactNode
  {
    return (
      <div className="py-24 md:py-36">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="mt-2 text-3xl text-slate-800 leading-8 font-extrabold tracking-tight sm:text-5xl">
            <Trans>Master</Trans> ChatGPT
          </h1>
          <p className="mt-4 max-w-2xl text-xl lg:mx-auto">
            <Trans>By mastering ChatGPT before everyone else, you will <span className="font-bold bg-gradient-to-r from-emerald-500 via-sky-500 to-indigo-500 bg-clip-text  text-transparent font-display">gain a significant competitive advantage in your professional career</span></Trans>
          </p>
        </div>

      <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-6">
        { sections.map((section) =>
          (

              <div className="my-2 w-full rounded-xl bg-gradient-to-r from-sky-100 via-emerald-100 to-indigo-100 p-1">
                <div className="p-8 md:h-64 lg:h-52 bg-slate-50 rounded-lg">

                  <h2 className="text-2xl font-bold mb-3">{section.title}</h2>

                  <p>{section.content}</p>

                  <div className="mt-5">
                    <a className="font-bold font-slate-500" target="_blank" href={constants.links.checkout}>➜ Get full access now</a>
                  </div>
                </div>
              </div>


          ))
        }
      </div>

      </div>
    </div>
    );
  }
}
