/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { Trans } from 'react-i18next';
import { constants } from '../../constants';
import { DownloadIcon } from '@heroicons/react/solid';
import { StaticImage } from 'gatsby-plugin-image';

const CTA = () => (
  <div className="w-full">

<div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
        <div className="overflow-hidden bg-gradient-to-r from-slate-900 to-indigo-900 rounded-2xl shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="font-bold tracking-tight text-white">
                <span className="block text-3xl sm:text-4xl">Ready to master ChatGPT?</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                Get access to an exclusive growing dataset of 100+ GPT tasks that you can include in your daily work to boost your productivty.
              </p>
              <a
                href={constants.links.checkout}
                target="_blank"
                className="mt-8 inline-flex items-center rounded-md border border-transparent rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 px-5 py-3 font-bold shadow"
              >
                <DownloadIcon className='mr-2 -ml-1 w-4 h-4'></DownloadIcon>
                <Trans>Get full access now</Trans>
              </a>
            </div>
          </div>
          <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
          <StaticImage className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20" src="../../images/mockups/gpt-dataset-mock.png" alt="Master ChatGPT" />


          </div>
        </div>
      </div>
    </div>
    </div>
)

export default CTA;