/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/sections/hero';
import Features from '../components/sections/features';
import Integrations from '../components/sections/integrations';
import Pricing from '../components/sections/pricing';
import { SEO } from '../components/seo';
import { useTranslation } from 'react-i18next';
import ROICalculator from '../components/sections/roi-calculator';
import CTA from '../components/sections/cta';
import { constants } from '../constants';

const IndexPage = () =>
{
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={ constants.app.name + ' - ' + t('Boost your productivity with ChatGPT')}
        description={ constants.app.name + ' - ' + t('Learn how to boost your productivity and streamline your daily work with ChatGPT. By mastering new AI technologies before everyone else, you will gain a significant competitive advantage in your professional career.')}
      ></SEO>
      <Layout>
        <Hero />
        <Features />
        <CTA />
        <ROICalculator />
      </Layout>
    </>
  );
};

export default IndexPage;

