/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { constants } from '../../constants';

export default function Hero()
{
  return (
    <div className="relative overflow-hidden">
      <div className="absolute inset-y-0 h-full w-full bg-gradient-to-r from-gray-900 to-neutral-900 clip-path-curved " aria-hidden="true">

      </div>

      <div className="relative py-10">

        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-bold bg-gradient-to-r from-sky-200 to-indigo-500 bg-clip-text text-transparent font-display sm:text-5xl md:text-5xl">
              <span className="block"><Trans>Boost your productivity with</Trans> <span className="font-extrabold">ChatGPT</span></span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-slate-400 sm:text-lg md:mt-5 md:text-3xl md:max-w-3xl ">
              <Trans>Thanks to 100+ GPT prompt inspirations tailored for <span className="font-bold text-transparent bg-gradient-to-r from-emerald-500 via-sky-400 to-indigo-500 bg-clip-text">entrepreneurs, marketers, sales, developers...</span></Trans>
            </p>
          </div>
        </div>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8 p-2">
          <div className="rounded-full shadow">
            <a
              href={constants.links.checkout}
              target="_blank"
              className="md:w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-indigo-600 hover:bg-indigo-700 md:py-3 md:text-xl md:px-10"
            >
              <Trans>➜ Get full access now</Trans>

            </a>
            <p className='mt-4 text-sm md:text-md font-bold  text-center'>100+ GPT prompt inspirations for only €19.99</p>
            <p className='text-sm md:text-md font-bold text-center'>3-day Money-back Guarantee</p>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="max-w-6xl mx-auto pb-8 px-4 sm:px-24 text-center">
        <a
              href={constants.links.checkout}
              target="_blank">
          <StaticImage className='rounded-lg' src="../../images/mockups/gpt-dataset-preview.png" alt="Preview"></StaticImage>
          </a>
        </div>
      </div>
    </div>
  )
}

